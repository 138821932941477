import * as React from "react";

import AuthContent from '../components/Login/AuthContent';
import Layout from "../components/Base/Layout";
import ShoppingHistory from "../components/Account/ShoppingHistory"
import { SEO } from "../components/Base/Seo";

const AccSettings = () => {
  return (
    <Layout>
      <AuthContent>
        <ShoppingHistory />
      </AuthContent>
    </Layout>
  );
}

export default AccSettings

export const Head = () => (
  <SEO title="Doctor Medica - Order History" description="Client Order History Page." />
)
